import { AppRoutes } from "./components/AppRoutes";
import { StickyNavbar } from "./components/StickyNavbar";
import { MsalAutoLogin } from "./components/MsalAutoLogin";
import { RefreshToken } from "./components/RefreshToken";
import { AuthProvider } from "./authContext";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";

// eslint-disable-next-line react/prop-types
function App({ msalInstance }) {
  return (
    <AuthProvider>
      <MsalProvider instance={msalInstance}>
        <RefreshToken />
        <MsalAutoLogin />
        <BrowserRouter>
          <StickyNavbar />
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
          <div className="flex">
            <div className="w-full p-4 h-[calc(100vh - 100px)]">
              <AppRoutes />
            </div>
          </div>
        </BrowserRouter>
      </MsalProvider>
    </AuthProvider>
  );
}

export default App;
