import React, { useEffect } from "react"
import { Routes, Route } from "react-router-dom";
import { HomePage } from "../pages/HomePage";
import { useAuth } from "../authContext";

export const AppRoutes = () => {

const { ...rest } = useAuth();

useEffect (() => {
}, [rest])

return (

<Routes>
  <Route
    path="/"
    element={<HomePage />}
  />
</Routes>
)}