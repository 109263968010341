import React, { useState, useContext, createContext } from "react";
import useLocalStorage from "./hooks/useLocalStorage";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = (props) => {
  const [username, setUsername] = useState(null);
  const [name, setName] = useState(null);
  const [roles, setRoles] = useState([]);
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [refreshToken, setRefreshToken] = useState(false);
  const value = {
    username,
    setUsername,
    name,
    setName,
    roles,
    setRoles,
    token,
    setToken,
    refreshToken,
    setRefreshToken,
    isAuthenticated,
    setIsAuthenticated,
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
