import { Navbar, Typography } from "@material-tailwind/react";
// Logo resources
import kyndrylLogo from "../assets/kyndrylLogo";
import { UserPopupState } from "./UserPopupState";

export const StickyNavbar = () => {
  return (
    <Navbar className="ml-0 pt-2 sticky flex items-end justify-between pl-5 pr-5">
      <div>
        <div className="flex">
          <img
            className="w-50 object-fit"
            alt="Kyndryl Logo"
            src={kyndrylLogo}
          />
          <div>
            <Typography className="text-lg text-black bg-white py-1.5 font-medium">
              MedBot
            </Typography>
            <Typography className="text-lg text-black bg-white py-1.5 font-medium">
              Testing Suite
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <Typography className="text-lg text-black bg-white py-1.5 font-medium">
          Links
        </Typography>
      </div>
      <div>
        <Typography className="text-lg text-black bg-white py-1.5 font-medium">
          Disclaimer
        </Typography>
      </div>
      <div>
        <Typography className="text-lg text-black bg-white py-1.5 font-medium">
          How to Use MedBot
        </Typography>
      </div>
      <UserPopupState />
    </Navbar>
  );
};
